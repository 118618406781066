.cardHeader {
  font-family: 'Orbitron', sans-serif;
  font-size: large;
  margin-bottom: 1rem;
  color: white;
  cursor: pointer;
  text-align: left;
  width: fit-content;
}

.baseBlock {
  width: 24rem;
  position: relative;
  font-family: 'Exo 2', sans-serif;
  display: flex;
  flex-direction: column;
  gap: .3rem;
  
  .header {
    display: flex;
    background-color: white;
    border-bottom: solid 1px rgb(202, 44, 25);
    padding: 0.6rem;
  }

  .body {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.6rem;
  }
}
