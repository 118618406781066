.blockHeader {
  font-family: 'Orbitron', sans-serif;
  font-size: x-large;
  margin-bottom: 1rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  width: fit-content;
  &:hover {
    border-bottom: solid 2px white;
  }
}

.lifePathBlockArray {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}