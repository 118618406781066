.content {
  overflow: auto;
  padding: 2rem 3.5rem;
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  .sidemenuButton {
    top: 0;
    left: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    width: 2.5rem;
    position: fixed;
    background-color: #db4435;
    color: white;
  }
}
