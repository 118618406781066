.npcName{
  font-family: 'Orbitron', sans-serif;
  font-size: x-large;
  font-weight: bold;
  color: white;
  padding: 1rem;
}

.content {
  display: flex;
  gap: 2rem;
  // font-family: 'Orbitron', sans-serif;
  font-family: 'Exo 2', sans-serif;

  .firstColumn {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .derivedStats {
      font-family: 'Orbitron', sans-serif;
      display: flex;
      gap: .3rem;
      width: 100%;
      >div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
        height: 3rem;
        background-color: white;
      }
    }

    .npcInfo {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    
      .infoBlock {
        display: flex;
        align-items: center;
        padding: .3rem;
        gap: 1rem;
        width: 100%;
        text-align: left;
        .infoBlockHeader {
          border: none;
          font-weight: bold;
          color: white;
          width: 25%;
          font-family: 'Orbitron', sans-serif;
          font-size: large;
        }
        .infoBlockContent {
          display: flex;
          flex-direction: column;
          gap: .3rem;
          width: 50%;
          >div {
            display: flex;
            padding-left: 1rem;
            align-items: center;
            background-color: white;
            height: 2rem;
          }
        }
      }
    }
  }
}