.baseBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;
  width: 24rem;
  font-family: 'Exo 2', sans-serif;

  .header {
    font-family: 'Orbitron', sans-serif;
    color: white;
    font-size: large;
  }

  .encounterBlock {
    background-color: white;
    height: 100%;
    padding: .3rem;
  }
}