.factItem {
  display: flex;
  flex-direction: column;
  gap: .4rem;
  width: 18rem;

  .header {
    font-family: 'Orbitron', sans-serif;
    display: flex;
    justify-content: space-between;
    text-align: start;
    position: relative;
    font-size: large;
    color: white;
    height: 2.5rem;
  }
  .body {
    background-color: white;
    padding: 0.2rem;
    overflow: auto;
    height: 5rem;
  }
}
