.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: 100vh;

  /* background-image: url('assets/background.png'); */
  /* background-size: cover; */
}

.link {
  padding: 5px;
  display: block;
  color: black;
  text-decoration: auto;
  min-width: 4rem;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e4e4e4;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #db4435;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #bb3529;
}

