.statOption {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Orbitron', sans-serif;
  cursor: default;
  height: 3rem;
  background-color: white;

  .tooltip {
    max-width: 25rem;
    font-family: 'Exo 2', sans-serif;
  }

  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: large;
  }
}