.drugsConstructor{
  border: solid 3px  rgb(202, 44, 25);
  width: 80%;
  .constructedDrugBlock{
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    border-bottom: solid 3px  rgb(202, 44, 25);
    height: 15rem;
    padding: .6rem;
  }
}