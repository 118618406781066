.dropdown {
  position: relative;
  width: 10rem;
  background-color: white;
  font-family: 'Orbitron', sans-serif;

  .dropdownInput {
    border: solid 2px #ca2c19;
    cursor: pointer;
    padding: .3rem;
    // min-width: 8rem;
  }
  .dropdownOptions {
    width: inherit;
    position: absolute;
    cursor: pointer;
    border: solid 2px #ca2c19;
    border-top: none;
    box-sizing: border-box;
    background-color: white;
    z-index: 3;

    >div {
      border-bottom: solid 1px #ca2c19;
      margin: .5rem;

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}