.streetratsBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2rem;
  .derivedStatsBlock {
    display: flex;
    gap: .3rem;
    padding: .3rem;
    font-family: 'Orbitron', sans-serif;
    height: 3rem;
    >div{
      flex: 1 1 auto;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}