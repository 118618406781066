.dataSelector{
  display: flex;
  width: 100%;
  height: 30rem;
  
  .infoSegment{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    padding: .6rem;
    overflow: auto;

    .dataCard {
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px  rgb(202, 44, 25);
      width: 10rem;
      height: 3rem;
      cursor: pointer;
      &.active {
        box-shadow: 4px 4px 0px 0px rgba(202, 43, 25, 0.7);
      }
    }
  }
  .controlsSegment{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 10rem;
    border-right: solid 3px  rgb(202, 44, 25);
    padding: .6rem;

    >button{
      display: flex;
      justify-content: center;
      align-items: center;
      border: dotted 1px  rgb(202, 44, 25);
      width: 10rem;
      height: 3rem;
      cursor: pointer;
      background-color: white;
    }
  }
}