.sideMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'Orbitron', sans-serif;
  width: 15rem;
  backface-visibility: hidden;

  .header{
    background-color: #db4435;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    color: white;
    cursor: pointer;
  }

  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .menuItem{
      margin-left: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title{
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 2rem;
      }
    }
  }
}