.cardHeader {
  font-family: 'Orbitron', sans-serif;
  color: white;
  font-size: large;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .4rem;

  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .2rem;

    &:first-of-type {
      margin-bottom: .3rem;
    }
    
    >div {
      background-color: white;
      padding: .3rem;
    }
    .type {
      width: 13rem;
      text-align: left;
    }
    .skill {
      width: 10rem;
    }
    .damage {
      width: 4rem;
    }
    .magazine {
      width: 6rem;
    }
    .rof {
      width: 2rem;
    }
    .handsReq {
      width: 6rem;
    }
    .concealability {
      width: 5rem;
    }
    .cost {
      width: 6rem;
    }
  }
}