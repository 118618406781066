.blockHeader {
  font-family: 'Orbitron', sans-serif;
  font-size: x-large;
  margin-bottom: 1rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  text-align: left;
  width: fit-content;
  &:hover {
    border-bottom: solid 2px white;
  }
}

.roleBlock {
  display: flex;
  gap: 1rem;

  .factBlockArray {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 1rem;
  }
}

.divider {
  width: 100%;
  height: 1px;
  margin: 0.3rem 0;
  background-color: #ca2c19;
}
