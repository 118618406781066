.completePackBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  .derivedStatsBlock {
    display: flex;
    flex-direction: column;
    gap: .3rem;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    font-family: 'Orbitron', sans-serif;
    >div{
      background-color: white;
      width: 100%;
      text-align: left;
      padding: .4rem;
    }
  }
}