.statsInfo{
  display: flex;
  align-items: center;

  .statsTable {
    padding: .3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: .3rem;
    width: 40rem;
  }
  
  .rerandomButton {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    height: 3rem;
    width: 3rem;
    font-size: 20pt;
    font-weight: bold;
    background-color: transparent;
    color: white;
  }

}