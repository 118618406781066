.skillsTable {
  position: relative;
  padding: .5rem;
  height: 60rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  align-content: space-between;

  @media screen and (max-width: 1679px) {
    height: 90rem;
  }

  .pointsTracker {
    position: absolute;
    top: -2rem;
    right: -1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Orbitron', sans-serif;
    height: 5rem;
    width: 5rem;
    font-size: 20pt;
    font-weight: bold;
    color: white;
  }

  .skillGroup {
    width: 32%;
    @media screen and (max-width: 1679px) {
      width: 48%;
    }

    .skillGroupHeader{
      font-family: 'Orbitron', sans-serif;
      font-size: large;
      color: white;
    }

    .skillGroupTable {
      padding: .4rem;
      display: grid;
      grid-template-columns: 50% 50%;
      gap: .3rem;
    }
  }
}