.generateButton {
  cursor: pointer;
  border-radius: .1rem;
  padding: .2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.3rem;
  width: 1.3rem;
  background-color: white;
  color: rgba(0, 0, 0, 0.85);
  border: none;
}